/* Import external stylesheets */
@import './media-library-pro.css';

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Font Declarations
 */
@font-face {
    font-family: 'proxima-nova-thin';
    src: url('../../../public/fonts/proxima-nova-thin.otf');
}

@font-face {
    font-family: 'proxima-nova-regular';
    src: url('../../../public/fonts/proxima-nova-regular.otf');
}

@font-face {
    font-family: 'proxima-nova-semibold';
    src: url('../../../public/fonts/proxima-nova-semibold.otf');
}

@font-face {
    font-family: 'proxima-nova-bold';
    src: url('../../../public/fonts/proxima-nova-bold.otf');
}

@font-face {
    font-family: 'proxima-nova-extrabold';
    src: url('../../../public/fonts/proxima-nova-extrabold.otf');
}

@font-face {
    font-family: 'proxima-nova-black';
    src: url('../../../public/fonts/proxima-nova-black.otf');
}

@font-face {
    font-family: 'proxima-nova-alt-thin';
    src: url('../../../public/fonts/proxima-nova-alt-thin.otf');
}

@font-face {
    font-family: 'proxima-nova-alt-alt-light';
    src: url('../../../public/fonts/proxima-nova-alt-light.otf');
}

@font-face {
    font-family: 'proxima-nova-alt-bold';
    src: url('../../../public/fonts/proxima-nova-alt-bold.otf');
}

/**
 * Base Styles and Theme Configuration
 */
@layer base {

    /* Set default font family */
    html {
        font-family: proximaNova, sans-serif, system-ui;
    }

    /* Core styles */
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }

    :root {
        --background: 0 0% 100%;
        --foreground: 20 14.3% 4.1%;
        --card: 0 0% 100%;
        --card-foreground: 20 14.3% 4.1%;
        --popover: 0 0% 100%;
        --popover-foreground: 20 14.3% 4.1%;
        --primary: 24.6 95% 53.1%;
        --primary-foreground: 60 9.1% 97.8%;
        --secondary: 60 4.8% 95.9%;
        --secondary-foreground: 24 9.8% 10%;
        --muted: 60 4.8% 95.9%;
        --muted-foreground: 25 5.3% 44.7%;
        --accent: 60 4.8% 95.9%;
        --accent-foreground: 24 9.8% 10%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 20 5.9% 90%;
        --input: 20 5.9% 90%;
        --ring: 24.6 95% 53.1%;
        --radius: 0.5rem;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
    }

    .dark {
        --background: 20 14.3% 4.1%;
        --foreground: 60 9.1% 97.8%;
        --card: 20 14.3% 4.1%;
        --card-foreground: 60 9.1% 97.8%;
        --popover: 20 14.3% 4.1%;
        --popover-foreground: 60 9.1% 97.8%;
        --primary: 20.5 90.2% 48.2%;
        --primary-foreground: 60 9.1% 97.8%;
        --secondary: 12 6.5% 15.1%;
        --secondary-foreground: 60 9.1% 97.8%;
        --muted: 12 6.5% 15.1%;
        --muted-foreground: 24 5.4% 63.9%;
        --accent: 12 6.5% 15.1%;
        --accent-foreground: 60 9.1% 97.8%;
        --destructive: 0 72.2% 50.6%;
        --destructive-foreground: 60 9.1% 97.8%;
        --border: 12 6.5% 15.1%;
        --input: 12 6.5% 15.1%;
        --ring: 20.5 90.2% 48.2%;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

/* Critical animations */
@keyframes move-x {
    0% {
        transform: translateX(var(--move-x-from));
    }

    100% {
        transform: translateX(var(--move-x-to));
    }
}

/* Essential layout styles */
#__next {
    min-height: 100%;
}

/* Non-critical styles loaded after main content */
@media screen {

    /* Form Element Overrides */
    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        --tw-ring-color: transparent;
    }

    /* Custom Component Styles */
    .ring-1 {
        --tw-ring-shadow: 0 0 0 1px rgba(246, 162, 59, 0.295);
    }

    .tox-toolbar__button:hover {
        background-color: orange;
        color: white;
    }

    .custom-list {
        list-style-type: none;
        counter-reset: item;
    }

    .custom-list li:before {
        content: counter(item, lower-alpha) '.';
        counter-increment: item;
        margin-right: 0.5rem;
        font-weight: bold;
    }

    /* Page Transitions */
    .page-transition-wrapper {
        position: relative;
        min-height: 100%;
    }

    .page-transition-wrapper>* {
        transition: opacity 0.2s ease-in-out;
    }

    .page-transition-wrapper.transitioning>* {
        opacity: 0;
    }
}

@layer utilities {
    .sidebar-expanded {
        padding-left: 16rem;
    }

    .sidebar-collapsed {
        padding-left: 0;
    }

    .sidebar-transition {
        transition-property: padding-left;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
    }
}
